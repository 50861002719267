
import Component from "vue-class-component";
import Vue, { PropType } from "vue";
import { gsap } from "gsap";
import {
  ChallengeCategories,
  ChallengeCategory,
} from "@/store/models/challengeTemplate";
import { Challenge } from "@/store/models/challenge";
import whoInTheGroup from "@/assets/categories/whoInTheGroup.svg";
import truthOrLie from "@/assets/categories/truthOrLie.svg";
import task from "@/assets/categories/task.svg";
import rule from "@/assets/categories/rule.svg";
import tournament from "@/assets/categories/tournament.svg";
import neverHaveIEver from "@/assets/categories/neverHaveIEver.svg";

const categoryIcons = {
  whoInTheGroup,
  truthOrLie,
  task,
  rule,
  tournament,
  neverHaveIEver,
};

const SelectionWheelProps = Vue.extend({
  props: {
    categoryDistribution: {
      type: Array as PropType<
        Array<{ category: ChallengeCategory; count: number }>
      >,
      required: true,
    },
    currentChallenge: {
      type: Object as PropType<Challenge>,
      required: true,
    },
    color: String,
  },
});

@Component<SelectionWheel>({
  components: categoryIcons,
  watch: {
    currentChallenge: {
      immediate: true,
      handler() {
        setTimeout(() => {
          this.spin();
        }, 5000);
      },
    },
  },
})
export default class SelectionWheel extends SelectionWheelProps {
  state: "ready" | "spinning" | "done" = "ready";

  get svgCategories() {
    const categories = ChallengeCategories.filter((c) =>
      this.categoryDistribution.some((d) => c === d.category)
    );
    return categories.map((category, index) => {
      const rad = ((index + 1) / categories.length) * 2 * Math.PI;
      const x = Math.sin(rad) * 150;
      const y = Math.cos(rad) * 150;
      return {
        x,
        y,
        rad,
        category: category,
        svg: categoryIcons[category],
      };
    });
  }

  spin(category?: ChallengeCategory): Promise<void> {
    if (this.state !== "ready") return Promise.resolve();

    category = category || this.currentChallenge.template.category;
    const categories = this.svgCategories;
    const cat = categories.find((s) => s.category === category)!;
    const rotation =
      (5 + Math.floor(Math.random() * 10)) * 360 -
      90 -
      (cat.rad * 180) / Math.PI;
    console.log(category, rotation);
    return new Promise<void>((resolve, reject) => {
      this.state = "spinning";
      gsap.fromTo(
        "#bottle",
        { rotation: 0 },
        {
          rotation,
          transformOrigin: "50% 50%",
          duration: 5,
          ease: "expo.out",
          onComplete: () => {
            this.state = "done";
            this.$emit("done");
            resolve();
          },
        }
      );
    });
  }
}
