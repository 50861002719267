
import Vue from "vue";
import Component from "vue-class-component";
import MobileView from "@/components/MobileView.vue";
import store from "@/store";

@Component({
  components: { MobileView },
  computed: {
    gameLength: {
      get() {
        return store.state.game.settings.length;
      },
      set(value) {
        store.state.game.settings.length = value;
        store.save();
      },
    },
    showBottleSpin: {
      get() {
        return !store.state.game.settings.hideBottleSpin;
      },
      set(value) {
        store.state.game.settings.hideBottleSpin = !value;
        store.save();
      },
    },
  },
})
export default class Settings extends Vue {
  code = "";
  error: string | null = null;

  useCode() {
    if (
      !store.state.packCodes.includes(this.code) &&
      store.state.packs.some((p) => p.code == this.code)
    ) {
      store.state.packCodes.push(this.code);
      this.$router.push({ name: "PackSelection" });
    } else {
      this.error = "Code invalid!";
    }
  }

  // TODO remove
  reset() {
    window.localStorage.clear();
    window.location.reload();
  }
}
