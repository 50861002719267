
import Component from "vue-class-component";
import Vue from "vue";
import { ChallengeCategory } from "@/store/models/challengeTemplate";
import { Challenge } from "@/store/models/challenge";
import { gsap } from "gsap";
import { Player } from "@/store/models/player";
import _ from "lodash";
import { Game } from "@/store/models/game";
import SelectionWheel from "@/components/SelectionWheel.vue";
import MobileView from "@/components/MobileView.vue";

@Component<VGame>({
  components: { MobileView, SelectionWheel },
  mounted() {
    if (!this.$store.game.playersReady || !this.$store.game.currentPack) {
      console.log("Game not ready");
      this.$router.push({
        name: "Home",
      });
    }
    if (this.currentChallenge?.initialized) {
      this.state = "selection";
    }
  },
  watch: {
    state: {
      immediate: true,
      handler: function () {
        this.currentChallenge!.init(_.shuffle(this.game.players!.slice()));
        switch (this.state) {
          case "selection":
            if (this.$store.game.settings.hideBottleSpin) {
              this.state = "challenge";
            }
            break;
          case "challenge":
            break;
        }
        this.$store.save();
      },
    },
    selectedPlayer: {
      immediate: true,
      handler: function () {
        Vue.nextTick(() => {
          this.scaleSelectedPlayerFontSize();
        });
      },
    },
  },
})
export default class VGame extends Vue {
  state: "selection" | "challenge" = "challenge";
  players: Player[] = [];

  get game(): Game {
    return this.$store.game!;
  }

  get currentChallenge(): Challenge | null {
    return this.$store.game.currentPack!.getCurrentChallenge();
  }

  get selectedPlayer(): Player | null | undefined {
    return this.currentChallenge?.players?.[0];
  }

  get currentChallengeText(): string {
    console.log("getText");
    return this.currentChallenge?.getText() || "";
  }

  get categoryDistribution(): Array<{
    category: ChallengeCategory;
    count: number;
  }> {
    const agg = this.game
      .currentPack!.getFutureChallenges()
      .reduce((agg: { [cat: string]: number }, c: Challenge) => {
        const cat = c.template.category as string;
        agg[cat] = agg[cat] || 0;
        agg[cat]++;
        return agg;
      }, {});
    return Object.keys(agg).map((cat) => {
      return { category: cat as ChallengeCategory, count: agg[cat] };
    });
  }

  async showChallenge() {
    setTimeout(() => {
      this.state = "challenge";
    }, 500);
  }

  chickenOut() {
    this.currentChallenge?.chickenOut();
  }

  next() {
    if (this.game.currentPack!.next() === null) {
      this.$router.push({
        name: "PackSelection",
      });
    }
    this.state = "selection";
  }

  scaleSelectedPlayerFontSize() {
    const container = document.getElementById("selected-player");

    if (!container) {
      return;
    }
    console.log("Scale");
    // Reset font-size to 100% to begin
    container.style.fontSize = "1000%";

    // Check if the text is wider than its container,
    // if so then reduce font-size
    if (container.scrollHeight > container.clientHeight) {
      container.style.fontSize =
        (container.clientHeight / container.scrollHeight) * 1000 + "%";
    }
  }
}
