var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('mobile-view',[_c('v-layout',{attrs:{"column":"","fill-height":""}},[_c('h1',{staticClass:"primary--text"},[_vm._v("Settings")]),_c('h3',{staticClass:"mt-5"},[_vm._v("Game Length")]),_c('v-select',{staticClass:"flex-grow-0",attrs:{"items":[
        { text: 'Short (15)', value: 15 },
        {
          text: 'Medium (30)',
          value: 30,
        },
        { text: 'Long (50)', value: 50 },
        { text: 'Full', value: 1000 },
      ]},model:{value:(_vm.gameLength),callback:function ($$v) {_vm.gameLength=$$v},expression:"gameLength"}}),_c('h3',{staticClass:"mt-5"},[_vm._v("Gameplay")]),_c('v-checkbox',{attrs:{"label":"Show Bottle Spin"},model:{value:(_vm.showBottleSpin),callback:function ($$v) {_vm.showBottleSpin=$$v},expression:"showBottleSpin"}}),_c('h3',{staticClass:"mt-5"},[_vm._v("Unlock Code")]),_c('v-text-field',{staticClass:"flex-grow-0",attrs:{"clearable":"","color":"primary","placeholder":"Enter Code ","error-messages":_vm.error},on:{"input":function($event){_vm.error = null}},model:{value:(_vm.code),callback:function ($$v) {_vm.code=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"code"}}),_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.useCode}},[_vm._v("Activate Code")]),_c('v-btn',{staticClass:"mt-auto",on:{"click":_vm.reset}},[_vm._v("RESET")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }