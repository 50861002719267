
import Vue from "vue";
import Component from "vue-class-component";
import { Player } from "@/store/models/player";
import MobileView from "@/components/MobileView.vue";

@Component({
  components: { MobileView },
})
export default class Overview extends Vue {
  players: Player[] = this.$store.game.players || [
    new Player(),
    new Player(),
    new Player(),
  ];

  addPlayer() {
    this.players.push(new Player());
  }

  start() {
    this.$store.state.game.players = this.players.filter(
      (p) => p.name && p.name.trim() !== ""
    );
    this.$store.save();
    this.$router.push({
      name: "Game",
    });
  }
}
