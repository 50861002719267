
import Component from "vue-class-component";
import Vue from "vue";
import { Pack } from "@/store/models/pack.ts";
import { PackTemplate } from "@/store/models/packTemplate";
import MobileView from "@/components/MobileView.vue";

@Component({
  components: { MobileView },
  mounted() {
    if (!this.$store.state.game.playersReady) {
      console.log("Players not ready");
      this.$router.push({
        name: "Home",
      });
    }
  },

  computed: {
    packs() {
      return this.$store.state.packs.filter(
        (p) => !p.code || this.$store.state.packCodes.includes(p.code)
      );
    },
  },
})
export default class PackSelection extends Vue {
  selectPack(pack: PackTemplate) {
    this.$store.game.selectPack(pack);
    this.$router.push({
      name: "Game",
    });
  }
}
